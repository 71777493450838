<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
        <div class="col-md-12"> 
          <filter-distributor
            show-search
            class="m-2"
            v-model:value="distributor"
            >
          </filter-distributor>
          
            <filter-toko
              show-search
              v-model:value="customer"
              class="m-2"
            ></filter-toko>
            
            <filter-brand 
            class="m-2" 
            v-model:value="brand"
            ></filter-brand>

        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <a-date-picker
              placeholder="Star Date"
              v-model:value="begin_date"
              :disabled-date="disabledStartDate"
              class="m-2"
              style="width: 300px"
            >
            </a-date-picker>
            <a-date-picker
              placeholder="End Date"
              v-model:value="end_date"
              :disabled-date="disabledEndDate"
              class="m-2"
              style="width: 300px"
            >
            </a-date-picker>

          <a-input-search
            v-model:value="q"
            placeholder="Cari ..."
            style="width: 300px"
            class="m-2"
          />
          <a-button class="m-2 mt-2" type="primary" title="cari" @click="search" :loading="isFetching">
            <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
            <span v-else>Memuat Data ...</span>
          </a-button>
        </div>
      </div>
      <div class="row justify-content-end mt-4">
        <div class="col-lg-6 col-md-12"></div>
        <div class="col-lg-6 col-md-12 text-right">
          <a-button
            title="Tambah Mapping"
            type="primary"
            @click="
              () => {
                formState = {}
                modal3Visible = true
                formState.title_state = 'Tambah Mapping'
                formState.state = 'new'
                formState.readOnly = false
              }
            "
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </a-button>
  
          <a-button
            class="ml-2"
            title="download excel"
            type="primary"
            @click="fetchXlsx"
            :loading="isDownloading"
          >
            <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
            <span v-else>Downloading ...</span>
          </a-button>
        </div>
      </div>
    <div class="table-responsive text-nowrap mt-2">
      <md-table
        :columns="columns"
        :data-source="data"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #active="{ text }">
          <a-tag v-if="text" color="#108ee9">Aktif</a-tag>
          <a-tag v-else color="grey">Tidak Aktif</a-tag>
        </template>
        <template #status="{ text }">
          <a-tag v-if="text" color="#108ee9">Disetujui oleh ASM</a-tag>
          <a-tag v-else color="grey">Belum disetujui oleh ASM</a-tag>
        </template>
        <template #colorBgDate="{ text, record }" >
          <span v-if=" record.end_date <= now_date" class="textBg">{{ text }}</span>
          <span v-else>{{ text }}</span>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Lihat Mapping">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                @click="lihatMapping(record.id)"
              >
                <small>
                  <i class="fe fe-eye" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Edit Mapping">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                @click="editMapping(record.id)"
              >
                <small>
                  <i class="fe fe-edit" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Hapus Mapping">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                @click="deleteMapping(record.id)"
              >
                <small>
                  <i class="fe fe-trash" />
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
  </div>
  <!-- <pre>{{ state }}</pre> -->
  <a-modal
    v-model:visible="modal3Visible"
    :title="formState.title_state"
    :destroy-on-close="true"
    width="1000px"
    :mask-closable="false"
    @ok="modal3Visible = false"
    v-if="modal3Visible"
  >
    <pre v-if="!true">{{ formState }}</pre>
    <template #footer>
      <a-button
        :loading="isPosting"
        key="submit"
        type="primary"
        @click="submit"
        :hidden="formState.readOnly"
        >Simpan</a-button
      >
    </template>
    <m-modal ref="modalRef"></m-modal>
  </a-modal>
</template>

<script>
import { ref, watch, onMounted, reactive, provide, toRefs, computed, h } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import apiClient from '@/services/axios'
import MModal from './modal'
import { Modal, message, Alert } from 'ant-design-vue'
import qs from 'qs'
import FilterToko from '@/components/filter/FilterToko'
import FilterBrand from '@/components/filter/FilterBrand'
import FilterDistributor from '@/components/filter/FilterDistributor'
import SelectToko from '@/components/select/SelectCustomer'
import { useDisabledDate } from '@/composables/DisabledBeginDate'
import moment from 'moment'
import SelectDistributor from '@/components/select/SelectDistributor'
import { serialize } from 'object-to-formdata'
import useUserInfo from '@/composables/useUserInfo'
import { columns } from './columns'
import _ from 'lodash'

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
}
export default {
  name: 'MDataToko',
  components: {
    MModal,
    FilterToko,
    FilterBrand,
    // SelectToko,
    // SelectDistributor,
    FilterDistributor,
  },
  setup() {
    const route = useRoute()
    // const modalRef = ref('')

    const modal1Visible = ref(false)
    const modal2Visible = ref(false)
    const modal3Visible = ref(false)
    const modal4Visible = ref(false)
    const modal5Visible = ref(false)
    const setModal1Visible = visible => {
      modal1Visible.value = visible
    }

    const loading = ref(false)

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)

    const region = ref([])
    const provinsi = ref([])
    const kabupaten = ref([])
    const segment = ref([])
    // const { isDistributor, vendor_id } = useUserInfo()
    // if (isDistributor.value) {
    //   distributor.value.push(vendor_id.value)
    // }
    const modalRef = ref('')

    const data = ref([])
    const q = ref('')
    const searchInput = ref(null)
    const isPosting = ref(false)
    const product = ref([])
    const brand = ref([])
    const distributor = ref([])
    const customer = ref([])
    const formState = ref({
      customer_id: null,
      vendor_id: null,
      brand_id: [],
    })

    const {
      startDate,
      endDate,
      begin_date,
      end_date,
      disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
    } = useDisabledDate()


    const errorMessage = ref(null)

    const startRow = ref(1)
    const handleTableChange = pag => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const params = ref({})
    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        q: q.value,
        customer: customer.value,
        product: product.value,
        brand: brand.value,
        distributor: distributor.value,
        begin_date: startDate.value,
        end_date: endDate.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/products-distributor', {
          params: _params,
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = error.response.data.message.substring(0, 150)
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    const lihatMapping = id => {
      apiClient
        .get(`/api/products-distributor/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal3Visible.value = true
          formState.value.title_state = 'Lihat Mapping'
          formState.value.readOnly = true
          formState.value.brands = formState.value.brand_id
         
        })
        .catch(error => console.error(error))
    }
    const editMapping = id => {
      apiClient
        .get(`/api/products-distributor/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal3Visible.value = true
          formState.value.title_state = 'Edit Mapping'
          formState.value.state = 'edit'
          formState.value.readOnly = false
          formState.value.brands = formState.value.brand_id
        })
        .catch(error => console.error(error))
    }

    const search = () => {
      fetchData()
    }

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/products-distributor', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params, ok: 'hmm' }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `products-Distributor_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }
    const submit = async () => {
      try {
        await modalRef.value.formRef.validate()
      } catch (error) {
        console.error('submit', error)
        return
      }

      const formatMoment = (value) => {
        return value instanceof moment ? value.format('YYYY-MM-DD') : value
      }

      if (formState.value.state === 'new') {
        Modal.confirm({
          title: 'Konfirmasi Ajukan Mapping',
          content: 'Apakah anda ingin melanjutkan Ajukan Mapping ?',

          onOk() {
            isPosting.value = true
            const form_data = serialize(
              {
                ..._.omit(formState.value, [
                  'state',
                  'title_state',
                  'readOnly',
                  'begin_date',
                  'end_date',
                  'distributor',
                ]),
              begin_date: formatMoment(formState.value.begin_date),
              end_date: formatMoment(formState.value.end_date),
              },
             { indices: true },
            )
            apiClient
              .post('/api/products-distributor', form_data)
              .then(({ data }) => {
                modal3Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil ditambah')
              })
              .catch(({ response: { data, status } }) => {
                if (status === 422) {
                  data[0].message
                } else data.message = 'Gagal menyimpan data! cek data kembali'
                Modal.error({
                  title: 'Gagal Simpan',
                  content: data[0].message,
                  onOk() {
                    console.log('ok')
                  },
                })
              })
              .finally(e => { isPosting.value = false })
          },
          onCancel() {},
        })
      } else if (formState.value.state === 'edit') {

        Modal.confirm({
          title: 'Konfirmasi Edit Mapping',
          content: 'Apakah anda ingin melanjutkan Edit Mapping ?',
          onOk() {
            isPosting.value = true

            const form_data = serialize(
              {
                ..._.omit(formState.value, [
                  'state',
                  'title_state',
                  'readOnly',
                  'begin_date',
                  'end_date',
                  'brands',
                ]),
                is_deleted: 0,
                brand_id: formState.value.brand_id,
                begin_date: formatMoment(formState.value.begin_date),
                end_date: formatMoment(formState.value.end_date),
              },
              { indices: true },
            )
            apiClient
              .post('/api/products-distributor/' + formState.value.id + '?_method=PUT', form_data)
              .then(({ data }) => {
                modal3Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil disimpan')
              })
              .catch(({ response: { data, status } }) => {
                if (status === 422) {
                  data[0].message
                } else data.message = 'Gagal menyimpan data! cek data kembali'
                Modal.error({
                  title: 'Gagal Simpan',
                  content: data[0].message,
                  onOk() {
                    console.log('ok')
                  },
                })
              })
              .finally(() => (isPosting.value = false))
          },
          onCancel() {},
        })
      }
    }

    const onSelectChange = (selectedRowKeys, selectedRows, dataIndex) => {
      state.selectedRowKeys = selectedRowKeys
      state.selectedRows = selectedRows
    }

    const visible = ref(false)

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })

    provide('formState', formState)

    onMounted(() => {
      fetchData()
    })

    const today = new Date()
    const now_date = (today.getFullYear() + '-' + (today.getMonth()+1) + '-' + today.getDate())
    console.log(now_date)

    const deleteMapping = id => {
      Modal.confirm({
        title: 'Konfirmasi hapus Mapping',
        content: 'Apakah anda ingin hapus Mapping ?',
        onOk() {
          apiClient
            .delete(`/api/products-distributor/${id}`)
            .then(response => {
              const data = response.data
              formState.value = data
              fetchData()
              message.success('Berhasil dihapus')
            })
            .catch(e => message.error('Gagal menghapus!'))
        },
        onCancel() {},
      })
    }
    

    return {
      perPage,
      pageCount,
      totalCount,
      meta,
      page,
      rowSelection,
      columns,
      data,
      q,
      searchInput,
      startRow,
      state,
      ...toRefs(state),
      fetchData,
      search,
      modal2Visible,
      modal3Visible,
      modal4Visible,
      modal5Visible,
      setModal1Visible,
      fetchXlsx,
      isPosting,
      formState,
      onSelectChange,
      visible,
      editMapping,
      lihatMapping,
      handleTableChange,
      submit,
      loading,
      confirm,
      modalRef,
      errorMessage,
      customer,
      product,
      brand,
      distributor,
      begin_date,
      end_date,
      disabledStartDate,
      disabledEndDate,
      today,
      now_date,
      deleteMapping,
    }
  },
}
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.ant-table-striped :deep(.table-striped) {
  background-color: #fafafa;
}

.textBg{
  color: rgb(255, 0, 0);
}
</style>
