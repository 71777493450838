export const columns = [
    //  {
    //   title: 'Tanggal Pendaftaran',
    //   dataIndex: 'created_at',
    // },
    {
      title: 'Begin Date',
      dataIndex: 'begin_date',
      slots: { customRender: 'colorBgDate' },
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      slots: { customRender: 'colorBgDate' },
    },
    {
      title: 'Kode Toko',
      dataIndex: 'toko_code',
    },
    {
      title: 'Nama Toko',
      dataIndex: 'toko',
    },
    {
      title: 'Kode Distributor',
      dataIndex: 'distributor_code',
    },
    {
      title: 'Nama Distributor',
      dataIndex: 'distributor',
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
    },
    {
      title: 'Action',
      slots: { customRender: 'action' },
    },
  ]
  
  