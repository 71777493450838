<template>
    <a-form
        ref="formRef"
        class="myform"
        :model="formState"
        :wrapper-col="{span: 14}"
        :scroll-to-first-error="true"
        :rules="rules"
    >
    <a-row class="form-row">
        <a-col :sm="24">
            <a-form-item
               label="Start Date"
               label-align="left"
               :label-col="{ sm: { span: 4 } }"
               :wrapper-col="{ sm: { span: 24 - 4 } }"
            >
            <a-date-picker
                style="width: 300px;"
                class="mr-2 mt-2"
                v-model:value="formState.begin_date"
                :disabled-date="c => disabledStartDated(c, formState.end_date)"
                placeholder=" Tanggal Awal"
                :disabled="formState.readOnly"
            >
            </a-date-picker>
            </a-form-item>
        </a-col>
    </a-row>

    <a-row class="form-row">
        <a-col :sm="24">
            <a-form-item
               label="End Date"
               label-align="left"
               :label-col="{ sm: { span: 4 } }"
               :wrapper-col="{ sm: { span: 24 - 4 } }"
            >
            <a-date-picker
                style="width: 300px;"
                class="mr-2 mt-2"
                v-model:value="formState.end_date"
                :disabled-date="c => disabledEndDated(formState.begin_date, c)"
                placeholder=" Tanggal Akhir"
                :disabled="formState.readOnly"
            >
            </a-date-picker>
            </a-form-item>
        </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Pilih Distributor"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          name="vendor_id"
        >
          <select-dist-customer
            show-search
            v-model:value="formState.vendor_id"
            v-model:customers="formState.customer_id"
            label="fullname"
            style="width: 100%"
            :disabled="formState.readOnly"
            :mode="null"
            required
          ></select-dist-customer>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Pilih Toko"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          name="customer_id"
        >
        <select-customer
         show-search 
         v-model:value="formState.customer_id"
         v-model:distributor="formState.vendor_id"
         label="fullname"
         style="width: 100%"
         :disabled="formState.readOnly"
         :mode="null"
         required
        ></select-customer>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Pilih Brand"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          name="brand_id"
        >
        <select-brand
         v-model:value="formState.brand_id"
         label="fullname"
         style="width: 100%"
         :disabled="formState.readOnly"
         :mode="null"
         required
        ></select-brand>
        </a-form-item>
      </a-col>
    </a-row>

    <!-- <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Pilih Product"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
        >
        <filter-product
         show-search 
         v-model:value="formState.product_id"
         label-fullname
         style="width: 100%"
         :disabled="formState.readOnly"
         :mode="null"
        ></filter-product>
        </a-form-item>
      </a-col>
    </a-row> -->

    </a-form>
</template>

<script>
    import {
    defineComponent,
    ref,
    reactive,
    inject,
    computed,
    nextTick,
    toRef,
    onBeforeMount,
    toRaw,
    toRefs,
    watch,
    } from 'vue'
    import apiClient from '@/services/axios'
    import SelectDistributor from '@/components/select/SelectDistributor'
    import { message } from 'ant-design-vue'
    import SelectDistCustomer from '@/components/select/SelectDistCustomer'
    import SelectBrand from '@/components/select/SelectBrand'
    import SelectCustomer from '@/components/select/SelectCustomer'
    import { useDisabledDate } from '@/composables/DisabledBeginDate'

    export default defineComponent({
        components:{
            // SelectDistributor,
            // FilterToko,
            // FilterDistributor,
            SelectDistCustomer,
            SelectBrand,
            SelectCustomer,
        },
        setup(props, { emit }){
            const defWrapCol = reactive({ md: { span: 16, offset: 4 }, sm: { span: 24 } })
            const formState = inject('formState')
            const visible = ref(false)
            const formRef = ref('')
            const dateFormat = 'YYYY/MM/DD';
            const isNew = computed(() => formState.value.state === 'new')
            const {
              startDate,
              endDate,
              begin_date,
              end_date,
              disabledStartDated,
              disabledEndDated,
            } = useDisabledDate()
            const brands = ref([])
            const rules = {
              vendor_id: {
                required: true,
                message: 'Distributor belum dipilih', 
              },
              customer_id: {
                required: true,
                message: 'Toko belum dipilih',
              },
              brand_id: {
                required: true,
                message: 'brand belum dipilih',
              },
            }


            return{
                formState,
                defWrapCol,
                isNew,
                visible,
                formRef,
                dateFormat,
                disabledStartDated,
                disabledEndDated,
                brands,
                rules,
            }
        },
    })
</script>

<style lang="scss" scoped>
 @import '@/css/form.scss';
</style>