<template>
    <a-select
      mode="multiple"
      v-model:value="valueState"
      style="width: 300px"
      placeholder="Pilih Brand"
      allow-clear
      not-found-content="Tidak ditemukan"
      option-filter-prop="label"
      option-label-prop="label"
      :show-arrow="true"
      @search="onSearch"
      @select="() => (findText = null)"
      v-bind="$attrs"
      :loading="loading"
    >
      <a-select-option v-for="(d, index) in data" :key="index" :value="d.id" :label="d.name">
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="highlight(d.name)"></span>
        <!--eslint-enable-->
      </a-select-option>
    </a-select>
  </template>
  
  <script>
  import { onMounted, ref, toRefs, watch } from 'vue'
  import apiClient from '@/services/axios'
  import { useVModel } from '@/components/useVModel.js'
  import { debounce, merge, keyBy, values } from 'lodash'
  
  export default {
    props: {
      value: {
        default: () => [],
        type: Array,
      },
      joinOptions: {
        default: () => [],
        type: Array,
      },
    },
    emits: ['update:value'],
    setup(props, { emit }) {
      const data = ref([])
      const findText = ref(null)
      const loading = ref(false)
      const joinOptions = toRefs(props).joinOptions
  
      const fetchData = (q = null) => {
        loading.value = true
        apiClient
          .get('/api/filter/brand?active=1', { params: { q } })
          .then(response => {
            let sorted = response.data
            if (joinOptions.value.length > 0) {
              const merged = merge(keyBy(joinOptions.value, 'id'), keyBy(sorted, 'id'))
              sorted = values(merged)
            }
            data.value = sorted.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
          })
          .finally(() => {
            loading.value = false
          })
      }
  
      onMounted(() => {
        fetchData()
      })
  
  
      const onSearch = debounce(value => {
        loading.value = true
        findText.value = value
        fetchData()
      }, 300)
  
      const highlight = value => {
        if (value === undefined) return
        return value.replace(new RegExp(findText.value, 'gi'), match => {
          return `<span style="background-color: yellow;">${match}</span>`
        })
      }
  
      watch(joinOptions, (after, before) => {
        if (after.length > 0) {
          const merged = merge(keyBy(after, 'id'), keyBy(data.value, 'id'))
          const sorted = values(merged)
          data.value = sorted.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        }
      })
  
      return { data, valueState: useVModel(props, 'value'), findText, highlight, onSearch, loading }
    },
  }
  </script>
  
  <style></style>
  