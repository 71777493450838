import { ref, watch } from 'vue'

export function useDisabledDate() {
  const begin_date = ref() // object momentjs
  const end_date = ref()
  const startDate = ref() // string sesuai backend server yyyy-mm-dd
  const endDate = ref()
  const firstDayMonth = ref() // string awal bulan tgl 1
  const lastDayMonth = ref()

  const disabledStartDate = begin_date => {
    if (!begin_date || !end_date.value) {
      return false
    }

    return begin_date.valueOf() > end_date.value.valueOf()
  }

  const disabledEndDate = end_date => {
    if (!end_date || !begin_date.value) {
      return false
    }

    return begin_date.value.valueOf() >= end_date.valueOf()
  }

  const format = yourDate => {
    if (!yourDate) return

    const offset = yourDate.getTimezoneOffset()
    yourDate = new Date(yourDate.getTime() - offset * 60 * 1000)
    return yourDate.toISOString().split('T')[0]
  }

  const getFirstDayMonth = value => {
    const d = new Date(value)
    const startDated = new Date(d.getFullYear(), d.getMonth(), 1)

    return format(startDated)
  }

  const getLastDayMonth = value => {
    const d = new Date(value)
    const dated = new Date(d.getFullYear(), d.getMonth() + 1, 0)

    return format(dated)
  }

  watch(begin_date, () => {
    // console.log('start_date', start_date.value)
    if (begin_date.value === null || begin_date.value === undefined) {
      firstDayMonth.value = null
      startDate.value = null
      return
    }
    const d = new Date(begin_date.value)
    const startDated = new Date(d.getFullYear(), d.getMonth(), 1)

    firstDayMonth.value = format(startDated)
    startDate.value = format(d)
  })

  watch(end_date, () => {
    if (end_date.value === null || end_date.value === undefined) {
      lastDayMonth.value = null
      endDate.value = null
      return
    }
    const d = new Date(end_date.value)
    const date = new Date(d.getFullYear(), d.getMonth() + 1, 0)

    lastDayMonth.value = format(date)
    endDate.value = format(d)
  })

  const disabledStartDated = (current, end_date) => {
    if (!current || !end_date) {
      return false
    }

    return current.valueOf() > end_date.valueOf()
  }

  const disabledEndDated = (begin_date, current) => {
    if (!current || !begin_date) {
      return false
    }

    return begin_date.valueOf() >= current.valueOf()
  }

  return {
    begin_date,
    end_date,
    startDate,
    endDate,
    disabledStartDate,
    disabledStartDated,
    disabledEndDate,
    disabledEndDated,
    firstDayMonth,
    lastDayMonth,
    format,
    getFirstDayMonth,
    getLastDayMonth,
  }
}
